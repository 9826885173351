import ReactDOM from 'react-dom'
import { Logo } from '@pmndrs/branding'
import './styles.css'
import App from './App'
/* fuck WOTC */


function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <a href="#" style={{ position: 'absolute', bottom: 20, left: 40, fontSize: '13px' }}>
        edhchina.com
        <br />
        China EDH
      </a>
      <div className='msgBox'>
        <p>为了维护中国玩家以及万智牌社区的集体利益，我们决定从今日起制定更适合中国玩家的CEDH(China EDH)禁牌表。</p>
        <p>To protect the collective interests of Chinese players and the MTG community, we have decided to establish a CEDH (China EDH) banlist that is more suitable for Chinese players starting today.</p>
        <p>make CEDH great again</p>

      </div>
      <div className='ListMain'>
      <img src='./wotc.png' style={{ position: 'absolute', top: '25px', right: 0,zIndex:'1555' }} width={200}></img>

        2024年9月24日
        <div className='ListTitle'>变更如下</div>


        <p>解禁<span className='grText'>🐒码头勒赎手</span>。</p>
        <p>解禁<span className='grText'>🪷珠光莲花</span>。</p>
        <p>解禁<span className='grText'>🪦魔法力墓穴</span>。</p>
        <p>解禁<span className='grText'>🐙万世创伤伊莫库</span>。</p>
        <p><span className='grText'>Dockside Extortionist</span> is unbanned.</p>
        <p><span className='grText'>Jeweled Lotus</span> is unbanned.</p>
        <p><span className='grText'>Mana Crypt</span> is unbanned.</p>
        <p><span className='grText'>Emrakul,the Aeons Torn</span> is unbanned.</p>

        <p style={{fontSize:'10px'}}>*如果你认可我们的表，请把这个网站分享给你身边的玩家以及社区。</p>




      </div>
      <div style={{ position: 'absolute', top: '0', left: '0',padding:'15px' }}>
        <h1 className='titleLar' style={{ margin: 0, padding: 0, fontSize: '5em', fontWeight: 500, letterSpacing: '-0.05em' }}>China EDH
        </h1>
      </div>
      {/* <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>pretty bad —</div> */}
      <div style={{ position: 'absolute', bottom: 20, right: 40, fontSize: '13px' }}>24/09/2024</div>
    </div>
  )
}

ReactDOM.render(
  <>
    <App /> <Overlay />
  </>,
  document.getElementById('root')
)
